import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = ['select']

  verifSelect(e) {
    let options = ['Animal Silvestre', 'Outro']
    let select = e.target
    let $group = $(select).parent().next()
    let select_value = $('option:selected', select).text()

    if ($.inArray(select_value, options) != -1) {
      $group.show()
    } else {
      $group.hide()
    }

    // apenas para o campo procedencia
    if (select_value === 'Outra') {
      $group.next().show()
    } else {
      $group.next().hide()
    }
  }

  connect() {
    console.log('START SELECT')

    const that = this
    this.selectTargets.forEach((el) => {
      el.addEventListener('change', this.verifSelect)
      that.trigger(el, 'change')
      el.removeEventListener('change', this.verifSelect)
    })
  }

}