import { Controller } from "stimulus"

export class ApplicationController extends Controller {

  trigger(el, type){
    if ('createEvent' in document) {
      // modern browsers, IE9+
      let e = document.createEvent('HTMLEvents')
      e.initEvent(type, false, true) //can bubble, and is cancellable
      el.dispatchEvent(e)
    } else {
      // IE 8
      let e = document.createEventObject()
      e.eventType = type
      el.fireEvent('on'+e.eventType, e)
    }
  }

  triggerClick(el) {
    let e = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    })

    el.dispatchEvent(e)
  }

}
