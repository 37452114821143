import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import $ from 'jquery'
import {} from 'jquery-ujs'
import 'jquery-mask-plugin'
import 'cocoon-js'
import 'select2'
import 'select2/select2_locale_pt-BR'
import 'qtip2'
import 'bootstrap'

import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

import ActionCable from 'actioncable'
window.cable = ActionCable.createConsumer()

import moment from 'moment'
window.moment = moment

import NProgress from 'nprogress'
window.NProgress = NProgress

import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(250)

import 'stylesheets/application'