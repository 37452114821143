import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START TOOLTIP')
    $('[data-toggle="tooltip"]').tooltip()
  }

}