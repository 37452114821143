import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initValues(e, item) {
    $(item).find('input[type=number]').val(0)
    $(item).find('#ano-adendo').val(new Date().getFullYear())
    $(item).find('#material-field').val('a ser Coletado')
  }

  connect() {
    console.log('START ESPECIE')

    $(document).on('cocoon:after-insert', this.initValues.bind(this))

  }

}