import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ 'button' ]

  verifRbChoose(e) {
    let options = ['true','Ensino','MUL','Múltipla']
    let $rb = $(e.target)
    let $group = $rb.parent().parent().next()
    let rb_value = $rb.val()

    if($.inArray(rb_value, options) != -1)
      $group.show()
    else
      $group.hide()
  }

  verifRbToogle(e) {
    var $rb = $(e.target)
    var $group1 = $rb.parent().parent().next()
    var $group2 = $rb.parent().parent().next().next()

    if($rb.val() in {'true':'', 'Ensino':'', 'MUL':''}) {
      $group1.show()
      $group2.hide()
    } else {
      $group1.hide()
      $group2.show()
    }
  }

  connect() {
    console.log('START RADIO BUTTON')

    const that = this
    this.buttonTargets.forEach((el, i) => {
      if (el.checked) {
        el.addEventListener('click', this.verifRbChoose)
        that.trigger(el, 'click')
        el.removeEventListener('click', this.verifRbChoose)
      }
    })

  }

}